import de from '../translations/de'
import en from '../translations/en'
type Translation = { [key: string]: any }

export type Lang = {
  langKey: LangKey
  translation: Translation
}

export type LangKey = string

export type DefaultState = {
  TRANSLATION: { [key: string]: any }
  CURRENTLANG: String
  LANGUAGES: Lang[]
}

const defaultState: DefaultState = {
  TRANSLATION: en.translation,
  CURRENTLANG: en.langKey,
  LANGUAGES: [en, de],
}

export default defaultState
