export const translation = {
  projectName: 'Blackflagship - Social Media',

  header: {
    navigation: {
      default: [
        {
          name: 'Login',
          link: '/',
        },
      ],
      ADMIN: [
        {
          name: 'Global Search',
          link: '/admin/search',
        },
        {
          name: 'Customers',
          link: '/admin/customer',
        },
      ],
      isLoggedIn: {
        name: 'Logout',
      },
    },
  },
  login: {
    headline: 'Login',
    cta: 'Login',
    error: 'Login failed. Wrong email or password.',
  },

  customer: {
    list: {
      titleSplit: {
        prefix: 'Your ',
        suffix: 'Customers.',
      },
      search: 'Search',
      employeeCount: 'Customers',
      copy: 'Copy external customer link',
    },
    upsert: {
      title: {
        create: 'Add Customer',
        update: 'Edit Customer',
      },
      logoUploadLabel: 'Upload Logo',
      success: 'The customer was successfully saved',
      error: 'The customer could not be saved',
    },
    attributes: {
      name: 'Name',
    },
  },

  sheet: {
    list: {
      titleSplit: {
        prefix: 'Your ',
        suffix: 'Sheets.',
        subline: (customerName: string) => customerName,
      },
      search: 'Search',
      employeeCount: 'Sheets',
      copy: 'Copy external sheet link',
    },
    upsert: {
      title: {
        create: 'Add Sheet',
        update: 'Edit Sheet',
      },
      logoUploadLabel: 'Upload Logo',
      success: 'The sheet was successfully saved',
      error: 'The sheet could not be saved',
    },
    attributes: {
      name: 'Name',
    },
    page: {
      new: '+ Add New Page',
      save: 'Save',
      remove: 'Delete',
      topic: 'Topic',
      text: 'Text',
      imageSelection: 'File Selection',
      imageSelectionHint: 'Drag-And-Drop (max. 1 image/video/PDF)',
      fileComment: 'File Comment',
      date: 'Date',
      time: 'Time',
      weekday: 'Weekday: ',
      localTime: 'Local Time: ',
      download: 'Download',
      copy: 'Copy',
      goToSheet: 'Open Sheet',
      calendarFullscreen: 'Show Calendar Fullscreen',
      success: {
        save: 'Page successfully saved',
      },
      error: {
        save: 'Page could not be saved.',
        remove: 'Page could not be deleted.',
        imageExtension: 'File is not allowed.',
        add: 'Page could not be added.',
      },
    },
    pageOverview: {
      noTopic: 'No Topic',
    },
    error: {
      loading: 'Content could not be loaded.',
      createPage: 'Page could not be created.',
    },
  },

  globalSearch: {
    placeholder: 'Search by topic, text or comment',
  },

  footer: {
    links: 'Links',
    followUs: 'Follow us on',
    legalLinks: [
      { name: 'Imprint', link: 'https://www.blackflagship.com/impressum' },
      { name: 'Privacy Policy', link: 'https://www.blackflagship.com/datenschutz' },
    ],
  },

  generic: {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    level: {
      title: 'Role',
      ADMIN: 'Administrator',
      MEMBER: 'Employee',
    },
    company: {
      name: 'Blackflagship Brand Agency GmbH',
    },
    timezoneFormat: 'MM/DD/YYYY HH:mm',
    settings: 'Settings',
    hourSuffix: ' hours',
    squareMeterSuffix: ' m²',
    equals: ' = ',
    timestampPrefix: 'Created on: ',
    phonePrefix: 'Phone: ',
    mailPrefix: 'Email: ',
    websitePrefix: 'Website: ',
    employeeSuffix: ' employees',
    salutation: 'Salutation',
    name: 'Name',
    firstname: 'First Name',
    lastname: 'Last Name',
    birthday: 'Date of Birth',
    personType: 'Type',
    personTypePlaceholder: 'Advisory Board, Managing Director, ...',
    street: 'Street, House Number',
    companyName: 'Company Name',
    club: 'Club (optional)',
    city: 'ZIP Code, City',
    mail: 'Email Address',
    identifier: 'Email Address',
    message: 'Your inquiry...',
    phone: 'Phone Number',
    password: 'Password',
    passwordRepeat: 'Repeat Password',
    createdOn: 'Created on',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    send: 'Send',
    error: 'An unexpected error occurred. Please try again later.',
    acceptAGB: 'I accept the Terms and Conditions',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Upload',
    login: 'Login',
    logout: 'Logout',
    export: 'Export',
    import: 'Import',
    save: 'Save',
  },

  UI: {
    copy: {
      success: 'Link has been copied',
    },
    removeConfirmation: {
      title: 'Confirmation',
      text: (itemName: string) => `Do you really want to delete "${itemName}"?`,
    },
    copyConfirmation: {
      title: 'Copy',
      text: (itemName: string) => `Rename ${itemName}-copy to:`,
      initNameSuffix: '-copy',
    },
    simpleList: {
      search: 'Search',
      resultAmountSuffixPlural: ' results',
      resultAmountSuffixSingular: ' result',
      noData: 'No data available',
    },
    multiList: {
      search: 'Search',
      resultAmountSuffix: ' results',
      noData: 'No data available',
      cta: 'Execute',
      cancelCTA: 'Cancel',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Process',
        STOP: 'Stop',
      },
      export: {
        title: 'Export',
        identifier: 'Identifier',
      },
      error: 'Action could not be executed',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Start Import',
      loading: 'Importing data...',
      error: 'The import failed.',
    },
    dropdown: {
      emptySelection: 'Please select',
      search: 'Search',
    },
    selectableTagList: {
      showMore: 'Show more',
      showLess: 'Show less',
    },
    textarea: {
      max: {
        prefix: 'maximum ',
        suffix: ' characters',
      },
    },
    dnd: {
      text: 'Attach application',
      hint: '(One PDF file up to 25 MB is allowed)',
      selectedPrefix: 'Selected PDF: ',
      error: {
        size: 'Maximum file size exceeded',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwords do not match',
    passwordReset: 'Password has been successfully changed',
    save: 'Change',
    password: 'Password',
    passwordRepeat: 'Repeat Password',
    title: 'Change Password',
  },
}

export const langKey = 'EN'

const Default = {
  translation,
  langKey,
}

export default Default
