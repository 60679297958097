import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logo from '../../../../assets/image/logo/anchor.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import useDidScroll from './useDidScroll'
import LanguageSwitcher from './LanguageSwitcher'

type Props = { hideNavigation?: boolean; disableDynamicHeight?: boolean; small?: boolean; hideHeader?: boolean }

const Header: React.FC<Props> = ({ hideNavigation = false, disableDynamicHeight = false, small = false, hideHeader = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink
  const t = useSelector((s) => s[props.TRANSLATION])
  const didScroll = useDidScroll()
  const goHome = () => {
    if (!accountLink) return
    let path = '/'
    if (isLoggedIn()) {
      path = accountLink.level === 'ADMIN' ? '/admin' : '/employee'
    }
    navigate(path)
  }

  return (
    <div
      className={`z-20 ${
        (didScroll && !disableDynamicHeight) || small ? 'h-12' : !hideHeader && 'h-16'
      } transition-all top-0 left-0 fixed w-full shadow-md flex flex-row items-center ${!!accountLink ? 'justify-between' : 'justify-center'} px-3`}
    >
      <div className={`absolute top-0 left-0 w-full h-full -z-10 bg-black`}></div>
      {!accountLink && (
        <div className="absolute right-0 top-0 h-full flex items-center pr-5">
          <LanguageSwitcher />
        </div>
      )}
      <div onClick={goHome} className={!!accountLink && 'cursor-pointer'}>
        <img src={logo} alt={t.projectName} className={(didScroll && !disableDynamicHeight) || small ? 'h-10' : 'h-12'} />
      </div>
      {!hideNavigation && !!accountLink && <Navigation />}
    </div>
  )
}

export default Header
