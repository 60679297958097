export const translation = {
  projectName: 'Blackflagship - Social Media',

  header: {
    navigation: {
      default: [
        {
          name: 'Anmelden',
          link: '/',
        },
      ],
      ADMIN: [
        {
          name: 'Globale Suche',
          link: '/admin/search',
        },
        {
          name: 'Kunden',
          link: '/admin/customer',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },
  login: {
    headline: 'Anmelden',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  customer: {
    list: {
      titleSplit: {
        prefix: 'Deine ',
        suffix: 'Kunden.',
      },
      search: 'Suche',
      employeeCount: 'Kunden',
      copy: 'Externen Kunden-Link kopieren',
    },
    upsert: {
      title: {
        create: 'Kunde hinzufügen',
        update: 'Kunde bearbeiten',
      },
      logoUploadLabel: 'Logo hochladen',
      success: 'Der Kunde wurde erfolgreich gespeichert',
      error: 'Der Kunde konnte nicht gespeichert werden',
    },
    attributes: {
      name: 'Name',
    },
  },

  sheet: {
    list: {
      titleSplit: {
        prefix: 'Deine ',
        suffix: 'Sheets.',
        subline: (customerName: string) => customerName,
      },
      search: 'Suche',
      employeeCount: 'Sheets',
      copy: 'Externen Sheet-Link kopieren',
    },
    upsert: {
      title: {
        create: 'Sheet hinzufügen',
        update: 'Sheet bearbeiten',
      },
      logoUploadLabel: 'Logo hochladen',
      success: 'Das Sheet wurde erfolgreich gespeichert',
      error: 'Das Sheet konnte nicht gespeichert werden',
    },
    attributes: {
      name: 'Name',
    },
    page: {
      new: '+ Neue Seite hinzufügen',
      save: 'Speichern',
      remove: 'Löschen',
      topic: 'Thema',
      text: 'Text',
      imageSelection: 'Dateiauswahl',
      imageSelectionHint: 'Drag-And-Drop (max. 1 Bild/Video/PDF)',
      fileComment: 'Kommentar zur Datei',
      date: 'Datum',
      time: 'Uhrzeit',
      weekday: 'Wochentag: ',
      localTime: 'Ortszeit: ',
      download: 'Herunterladen',
      copy: 'Kopieren',
      goToSheet: 'Sheet öffnen',
      calendarFullscreen: 'Kalender Vollbild anzeigen',
      success: {
        save: 'Seite erfolgreich gespeichert',
      },
      error: {
        save: 'Seite konnte nicht gespeichert werden.',
        remove: 'Seite konnte nicht gelöscht werden.',
        imageExtension: 'Datei ist nicht erlaubt.',
        add: 'Seite konnte nicht hinzugefügt werden.',
      },
    },
    pageOverview: {
      noTopic: 'Kein Thema',
    },
    error: {
      loading: 'Inhalte konnte nicht geladen werden.',
      createPage: 'Seite konnte nicht angelegt werden.',
    },
  },

  globalSearch: {
    placeholder: 'Suche nach Thema, Text oder Kommentar',
  },

  footer: {
    links: 'Links',
    followUs: 'Folge uns auf',
    legalLinks: [
      { name: 'Impressum', link: 'https://www.blackflagship.com/impressum' },
      { name: 'Datenschutz', link: 'https://www.blackflagship.com/datenschutz' },
    ],
  },

  generic: {
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
      MEMBER: 'Mitarbeiter',
    },
    company: {
      name: 'Blackflagship Markenagentur GmbH',
    },
    timezoneFormat: 'DD.MM.YYYY HH:mm',
    settings: 'Einstellungen',
    hourSuffix: ' Stunden',
    squareMeterSuffix: ' m²',
    equals: ' = ',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
  },

  UI: {
    copy: {
      success: 'Link wurde kopiert',
    },
    removeConfirmation: {
      title: 'Bestätigung',
      text: (itemName: string) => `Möchtest du "${itemName}" wirklich löschen?`,
    },
    copyConfirmation: {
      title: 'Kopieren',
      text: (itemName: string) => `${itemName}-Kopie umbenennen zu:`,
      initNameSuffix: '-Kopie',
    },
    simpleList: {
      search: 'Suche',
      resultAmountSuffixPlural: ' Ergebnisse',
      resultAmountSuffixSingular: ' Ergebnis',
      noData: 'Keine Daten vorhanden',
    },
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Maximale Dateigröße überschritten',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'

const Default = {
  translation,
  langKey,
}

export default Default
