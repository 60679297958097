import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import deLocale from '@fullcalendar/core/locales/de'
import enLocale from '@fullcalendar/core/locales/en-gb'
import './style.css'
import { Page as PageType } from '../../../types/Page'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Modal from '../Modal/Modal'
import { useState } from 'react'
import Page from '../../Public/Sheet/SheetView/Page/Page'
import getSinglePageSheetManager from '../../Public/Sheet/SheetView/Page/useSheetManager/getSinglePageSheetManager'
import { getAverageDate } from '../../../utility'

type Props = {
  pages: PageType[]
}

const FullScreenCalendar: React.FC<Props> = ({ pages }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [detailViewEventID, setDetailViewEventID] = useState<string>(null)
  const currentLang = useSelector((s) => s[props.CURRENTLANG])

  const getEvents = () => {
    if (Array.isArray(pages)) {
      return pages.map((p, i) => {
        return {
          id: p.uuid,
          start: p.date,
          allDay: true,
          // backgroundColor: getGrayColor(255 - i * 25),
          backgroundColor: '#212529',
        }
      })
    }
  }

  const renderEvent = (eventInfo: any) => {
    const page = pages.find((p) => p.uuid === eventInfo.event.id)

    if (!page) return null
    return (
      <div className={'flex flex-row items-center items cursor-pointer w-full basis-full py-0 px-0 gap-2 shadow-calendar-entry'}>
        <div className="flex flex-row gap-2">
          <div className={'text-s text-wrap p-2 text-white'}>{`${page.topic || t.sheet.pageOverview.noTopic}`}</div>
        </div>
      </div>
    )
  }

  const openDetailView = (info: any) => {
    setDetailViewEventID(info.event.id)
  }

  const closeDetailView = () => {
    setDetailViewEventID(null)
  }

  const getSinglePage = (uuid: string) => {
    return pages.find((p) => p.uuid === uuid)
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height={'calc(100vh - 175px)'}
        events={getEvents()}
        eventContent={renderEvent}
        eventClick={openDetailView}
        eventDisplay="block"
        timeZone="utc"
        locale={currentLang === 'DE' ? deLocale : enLocale}
        fixedWeekCount={false}
        initialDate={getAverageDate(pages)}
        titleFormat={{ year: 'numeric', month: 'long' }}
        headerToolbar={{
          left: 'dayGridWeek,dayGridMonth',
          center: 'title',
          right: 'today,prev,next',
        }}
      />
      <Modal large noForm show={!!detailViewEventID} onClose={closeDetailView} closeIcon>
        {!!detailViewEventID && (
          <Page pageUUID={detailViewEventID} sheetManager={getSinglePageSheetManager(getSinglePage(detailViewEventID))} readOnly noBorder />
        )}
      </Modal>
    </>
  )
}

export default FullScreenCalendar
