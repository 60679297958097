import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { LangKey } from '../../../../redux/defaultState'
import { setLocal } from '../../../../redux/action/local'
import cookie, { LANG } from '../../helper/cookie'
import { useEffect } from 'react'

const LanguageSwitcher = ({ className = 'text-white' }: { className?: string }) => {
  const currentLang = useSelector((s) => s[props.CURRENTLANG])
  const languages = useSelector((s) => s[props.LANGUAGES])
  const dispatch = useDispatch()

  const switchLang = (targetLang: LangKey) => {
    const newTranslation = languages.find((l) => l.langKey === targetLang)
    dispatch(setLocal(props.TRANSLATION, newTranslation.translation))
    dispatch(setLocal(props.CURRENTLANG, newTranslation.langKey))
    cookie.set(LANG, newTranslation.langKey)
  }

  useEffect(() => {
    const lang = cookie.get(LANG)
    if (lang) {
      if (!currentLang || currentLang !== lang) {
        dispatch(setLocal(props.CURRENTLANG, lang))
        const newLang = languages.find((l) => l.langKey === lang)
        dispatch(setLocal(props.TRANSLATION, newLang.translation))
      }
    }
  }, [currentLang, dispatch, languages])

  return (
    <button onClick={() => switchLang(currentLang === 'DE' ? 'EN' : 'DE')} className={`w-fit text-white hover:text-gray-300 ${className}`}>
      {currentLang}
    </button>
  )
}

export default LanguageSwitcher
